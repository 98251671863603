import { version } from '$app/environment'
import { PUBLIC_CASCA_ENV, PUBLIC_ROLLBAR_TOKEN } from '$env/static/public'
import { createPayloadFromSession, fallback } from '$lib/rollbar.js'
import { session } from '$lib/session.js'
import Rollbar from 'rollbar'
import { get } from 'svelte/store'

export default (
  PUBLIC_ROLLBAR_TOKEN
    ? new Rollbar({
      accessToken: PUBLIC_ROLLBAR_TOKEN,
      autoInstrument: true,
      captureEmail: true,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: PUBLIC_CASCA_ENV,
        client: {
          javascript: {
            code_version: version,
            source_map_enabled: true,
          },
        },
      },
      transform (payload) {
        Object.assign(payload, createPayloadFromSession(get(session)))
      },
    })
    : fallback
)
